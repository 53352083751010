import api_link from '../../config/variables.js';
import refreshToken from '../../components/controllers/refreshToken.vue';

export default {
   namespaced: true,
   state() {
      return {
         installations: [],
         singleInstallation: null,
         isEdit: 0,
         user_name: null,
         selectedUser: null
      };
   },
   mutations: {
      installations(state, payload) {
         state.installations = payload.data;
      },
      singleInstallation(state, payload) {
         state.singleInstallation = payload.data[0];
      },
      isEdit(state, payload) {
         state.isEdit = payload
      },
      user_name(state, payload) {
         state.user_name = payload.name
      },
      selectedUser(state, payload) {
         state.selectedUser = payload
      }
   },
   actions: {
      async getInstallations(context) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/installations/overview', {
               method: 'GET',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               }
            });
            const responseInstallations = await response.json();
            if (!response.ok) {
               const error = new Error(responseInstallations.error || 'Failed to load data from API!');
               throw error;
            }

            context.commit('installations', responseInstallations);
         }
      },

      async getSingleInstallation(context, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            if (payload.isEdit == 1) {
               const token = this.getters["auth/token"];
               const response = await fetch(api_link + '/installations/' + payload.id, {
                  method: 'GET',
                  headers: {
                     'Authorization': 'Bearer ' + token,
                     'Content-Type': 'application/json',
                  }
               });
               const responseInstallations = await response.json();

               if (!response.ok) {
                  const error = new Error(responseInstallations.error || 'Failed to load data from API!');
                  throw error;
               }

               context.commit('singleInstallation', responseInstallations);
            }
            context.commit('isEdit', payload.isEdit);

            context.commit('selectedUser', payload.selectedUser);
         }
      },
      async createInstallation(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/installations', {
               method: 'POST',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify([{
                  installation_id: payload.installation_id,
                  user_id: payload.user_id,
                  licensing_id: payload.licensing_id,
                  name: payload.name,
                  disabled: payload.disabled
               }]),
            });
            const responseInstallation = await response.json();

            if (!response.ok) {
               const error = new Error(responseInstallation.error || 'Failed to create installation!');
               throw error;
            }
         }
      },
      async updateInstallation(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/installations/' + payload.id, {
               method: 'PATCH',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify([{
                  user_id: payload.user_id,
                  licensing_id: payload.licensing_id,
                  name: payload.name,
                  disabled: payload.disabled
               }]),
            });
            const responseInstallations = await response.json();

            if (!response.ok) {
               const error = new Error(responseInstallations.error || 'Failed to update installation!');
               throw error;
            }
         }
      },
      async deleteInstallation(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/installations/' + payload.id, {
               method: 'DELETE',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               }
            });
            const responseInstallations = await response.json();

            if (!response.ok) {
               const error = new Error(responseInstallations.error || 'Failed to delete installation!');
               throw error;
            }
         }
      },
      async filterInstallationsToUser(context, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            if (payload.isFilter == 0) {
               context.commit('user_name', payload);
            } {
               context.commit('user_name', payload);
            }
         }
      }
   },
   getters: {
      installations(state) {
         return state.installations;
      },
      singleInstallation(state) {
         return state.singleInstallation
      },
      isEdit(state) {
         return state.isEdit;
      },
      user_name(state) {
         return state.user_name
      },
      selectedUser(state) {
         return state.selectedUser
      }
   }
}