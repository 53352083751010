import api_link from '../../config/variables.js';
import refreshToken from '../../components/controllers/refreshToken.vue';

export default {
   namespaced: true,
   state() {
      return {
         allUserToAccount: [],
         usersPermission: []
      };
   },
   mutations: {
      setAllUserToAccount(state, payload) {
         state.allUserToAccount = payload
      },
      usersPermission(state, payload) {
         state.usersPermission = payload
      }
   },
   actions: {
      async allUserToAccount(context) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = JSON.parse(window.localStorage.getItem("token")).token;
            const currenUser_id = JSON.parse(window.localStorage.getItem("currentUser")).user_id;
            const currenAccount_id = JSON.parse(window.localStorage.getItem("currentUser")).account_id;
            const response = await fetch(api_link + '/access/users/?search={"includeFields": ["user_id","firstname","lastname" ,"username"],"filter":[{"op":"eq","field":"account_id","value":"' + currenAccount_id + '"},{"op":"noteq","field":"user_id","value":"' + currenUser_id + '"}]}', {
               method: 'GET',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               },
            });
            const responseAllUserToAccount = await response.json();

            if (!response.ok) {
               const error = new Error(responseAllUserToAccount.error || 'Failed to load data from API!');
               throw error;

            }

            context.commit('setAllUserToAccount', responseAllUserToAccount.data);
         }
      },
      async usersPermission(context) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = JSON.parse(window.localStorage.getItem("token")).token;
            const user_id = JSON.parse(window.localStorage.getItem("currentUser")).user_id;
            const response = await fetch(api_link + '/access/users/usersPermission/' + user_id, {
               method: 'GET',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               }
            });
            const responseUsersPermission = await response.json();

            if (!response.ok) {
               const error = new Error(responseUsersPermission.error || 'Failed to load data from API!');
               throw error;

            }

            context.commit('usersPermission', responseUsersPermission.data);
         }
      },
      async updateUsersPermission(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = JSON.parse(window.localStorage.getItem("token")).token;
            const response = await fetch(api_link + '/access/users/usersPermission/' + payload.user_area_id, {
               method: 'PATCH',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify([
                  payload.newData
               ])
            });
            const responseUpdateUsersPermission = await response.json();

            if (!response.ok) {
               const error = new Error(responseUpdateUsersPermission.error || 'Failed to load data from API!');
               throw error;

            }
         }
      }
   },
   getters: {
      allUserToAccount(state) {
         return state.allUserToAccount
      },
      usersPermission(state) {
         return state.usersPermission
      }
   }
}