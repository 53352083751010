<template>
  <div>
    <custom-message
      :show="!!error"
      mode="error"
      title="Fehlermeldung"
      :decline="decline"
      @close="errorMessage"
    >
      <p>{{ error }}</p>
    </custom-message>
    <h1 class="mt-5">Login</h1>
    <form @submit.prevent="login">
      <div class="mb-3">
        <label class="form-label">{{ $t("username") }}</label>
        <input class="form-control" type="text" v-model="username" />
      </div>
      <div class="mb-3">
        <label class="form-label">{{ $t("password") }}</label>
        <input
          class="form-control"
          required
          type="password"
          v-model="password"
        />
      </div>
      <div class="mb-3">
        <button
          class="btn btn-light"
          type="submit"
          :disabled="!username || !password"
        >
          {{ $t("button-login") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      error: null,
      decline: null,
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("auth/login", {
          username: this.username,
          password: this.password,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.decline = "Schließen";
      }
    },
    errorMessage() {
      this.error = null;
    },
  },
  async created() {
    await this.$store.dispatch("auth/logout");
    localStorage.clear();
  },
};
</script>

<style scoped>
form {
  margin-left: 1rem;
  max-width: 500px;
}
button {
  width: 110px;
}
</style>