import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import authenticateModule from './modules/authenticate.js';
import permissionModule from './modules/permissions.js';
import userModule from './modules/users.js';
import installationModule from './modules/installations.js';
import watchdogModule from './modules/watchdogs.js';

const store = createStore({
  // plugins: [createPersistedState({
  //   paths: ["auth"],
  // })],
  modules: {
    auth: authenticateModule,
    permission: permissionModule,
    user: userModule,
    installation: installationModule,
    watchdog: watchdogModule,
  },
});

export default store;
