<template>
  <header>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainmenu"
          aria-controls="mainmenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="mainmenu">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/welcome">{{
              $t("the-header.labels.welcome")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/permissions">{{
                $t("the-header.labels.permission")
              }}</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/users">{{
                $t("the-header.labels.user")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/installations">{{
                $t("the-header.labels.installation")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/watchdogs">{{
                $t("the-header.labels.watchdogs")
              }}</router-link>
            </li> -->
            <li class="nav-item">
              <router-link class="nav-link" @click="logout()" to="/">{{
                $t("the-header.labels.logout")
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <hr />
  </header>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      menuVisible: false,
      permissions: null,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        window.localStorage.clear();
      });
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
  computed: {
    getToken() {
      return this.$store.getters["auth/token"];
    },
    maxwidth922() {
      if (this.windowWidth <= 992) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.permissions = await this.$store.getters["auth/permissions"];
  },
};
</script>

<style scoped>
nav {
  margin-top: 1rem;
}
li {
  margin: 0 2rem;
}
</style>