<template>
  <div>
    <custom-message
      :show="!!error"
      mode="error"
      title="Fehlermeldung"
      :decline="decline"
      @close="errorMessage"
    >
      <p>{{ error }}</p>
    </custom-message>
    <the-header></the-header>
    <h1>{{ $t("page-title-welcome") }}</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      decline: null,
    };
  },
  methods: {
    errorMessage() {
      this.error = null;
    },
  },
};
</script>

<style>
</style>