export default {
  "button-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "grid-caption-area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich"])},
  "grid-caption-read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesen"])},
  "grid-caption-write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben"])},
  "grid-caption-receive_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten erhalten"])},
  "page-title-welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "page-title-permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "the-header": {
    "labels": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
      "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
      "installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationen"])},
      "watchdogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watchdogs"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])}
    }
  },
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])}
}