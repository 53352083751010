<template>
  <div>
    <custom-message
      :show="!!error"
      mode="error"
      title="TokenrefreshError"
      :decline="decline"
      @close="errorMessage"
    >
      <p>{{ error }}</p>
    </custom-message>
  </div>
</template>

<script>
import store from "../../store/index.js";
export default {
  data() {
    return {
      error: null,
      decline: null,
      store,
    };
  },
  methods: {
    async checkToken() {
      if (JSON.parse(window.localStorage.getItem("token")).token) {
        try {
          await store.dispatch("auth/checkToken");
        } catch (error) {
          this.error = error.message || "Something went wrong!";
          this.decline = "Schließen";
        }
      }
    },
    errorMessage() {
      this.error = null;
    },
  },
};
</script>
