import api_link from '../../config/variables.js';
import refreshToken from '../../components/controllers/refreshToken.vue';

export default {
   namespaced: true,
   state() {
      return {
         users: [],
         singleUser: null,
         isEdit: 0,
         onlyUserNames: null,
      };
   },
   mutations: {
      users(state, payload) {
         state.users = payload.data;
      },
      singleUser(state, payload) {
         state.singleUser = payload.data[0];
      },
      isEdit(state, payload) {
         state.isEdit = payload
      },
      onlyUserNames(state, payload) {
         state.onlyUserNames = payload
      }

   },
   actions: {
      async getUsers(context) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/users', {
               method: 'GET',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               }
            });
            const responseUsers = await response.json();
            if (!response.ok) {
               const error = new Error(responseUsers.error || 'Failed to load data from API!');
               throw error;
            }
            context.commit('users', responseUsers);
         }

      },
      async getSingleUser(context, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            if (payload.isEdit == 1) {
               const token = this.getters["auth/token"];
               const response = await fetch(api_link + '/users/' + payload.id, {
                  method: 'GET',
                  headers: {
                     'Authorization': 'Bearer ' + token,
                     'Content-Type': 'application/json',
                  }
               });
               const responseUsers = await response.json();
               if (!response.ok) {
                  const error = new Error(responseUsers.error || 'Failed to load data from API!');
                  throw error;
               }
               context.commit('singleUser', responseUsers);
            }
            context.commit('isEdit', payload.isEdit);
         }
      },
      async createUser(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/users/signup', {
               method: 'POST',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify([{
                  user_id: payload.user_id,
                  customernumber: payload.customernumber,
                  name: payload.name,
                  username: payload.username,
                  password: payload.password,
                  email: payload.email,
                  admin: payload.admin,
                  disabled: payload.disabled
               }]),
            });
            const responseUser = await response.json();

            if (!response.ok) {
               const error = new Error(responseUser.error || 'Failed to create User!');
               throw error;
            }
         }
      },
      async updateUser(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/users/' + payload.id, {
               method: 'PATCH',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify([{
                  user_id: payload.user_id,
                  customernumber: payload.customernumber,
                  name: payload.name,
                  username: payload.username,
                  password: payload.password,
                  admin: payload.admin,
                  disabled: payload.disabled
               }]),
            });
            const responseUsers = await response.json();
            if (!response.ok) {
               const error = new Error(responseUsers.error || 'Failed to update User!');
               throw error;
            }
         }
      },
      async deleteUser(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/users/' + payload.id, {
               method: 'DELETE',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               }
            });
            const responseUsers = await response.json();

            if (!response.ok) {
               const error = new Error(responseUsers.error || 'Failed to delete user!');
               throw error;
            }
         }
      },
      async onlyUserNames(context) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/users?search={"includeFields": [ "user_id","name"]}', {
               method: 'GET',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               }
            });
            const responseUsers = await response.json();

            if (!response.ok) {
               const error = new Error(responseUsers.error.message || 'Failed to load usernames!');
               throw error;
            }
            context.commit('onlyUserNames', responseUsers.data);
         }
      },
   },
   getters: {
      users(state) {
         return state.users;
      },
      singleUser(state) {
         return state.singleUser
      },
      isEdit(state) {
         return state.isEdit;
      },
      onlyUserNames(state) {
         return state.onlyUserNames;
      }

   }
}