import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { createApp } from 'vue';

import router from './router.js';
import store from './store/index.js'
import App from './App.vue';
import i18n from './i18n';

import CustomMessage from './components/ui/CustomMessage.vue';
import TheHeader from './components/layout/TheHeader.vue';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(i18n);
app.component('custom-message', CustomMessage);
app.component('the-header', TheHeader);

app.mount('#app');