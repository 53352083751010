<template>
  <main>
    <router-view></router-view>
    <check-logout></check-logout>
  </main>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import checkLogout from "./components/controllers/autoLogout.vue";

export default {
  components: { checkLogout },
};
</script>

<style>
* {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 17px;
}
h1 {
  font-size: 1.5rem;
  font-weight: 700;
  padding-left: 2rem;
}
h2 {
  font-size: 1.5rem;
}
.router-link-active {
  font-weight: bold;
}
.grid-title {
  position: relative;
  z-index: 1;
  top: 1.5rem;
  width: 200px;
}
a {
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
table a {
  color: #005b7d !important;
}
form {
  padding: 1rem;
}
.dx-datagrid-header-panel .dx-toolbar {
  margin: 2rem 0 3rem 0 !important;
}
.dx-datagrid-search-panel {
  width: 150px !important;
}
.dx-datagrid {
  color: #333;
  background-color: #fff;
  line-height: inherit;
  top: 0rem;
}
@media (min-width: 460px) {
  .dx-datagrid {
    top: -3.1rem;
  }
}
@media (min-width: 520px) {
  h1 {
    font-size: 2rem;
  }

  .dx-datagrid-search-panel {
    width: 200px !important;
  }
}
@media (min-width: 992px) {
  .dx-datagrid-search-panel {
    width: 500px !important;
  }
}
</style>