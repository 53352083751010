<template>
  <div>
    <custom-message
      :show="!!error"
      mode="error"
      title="TokenrefreshError"
      :decline="decline"
      @close="errorMessage"
    >
      <p>{{ error }}</p>
    </custom-message>
  </div>
</template>

<script>
export default {
  data() {
    return {
      events: ["mousemove", "mousedown", "scroll", "keypress", "load"],
      logoutTimer: null,
      error: null,
      decline: null,
    };
  },
  methods: {
    setTimers() {
      if (window.localStorage.getItem("token") != null) {
        this.logoutTimer = setTimeout(this.logoutUser, 60 * 5000); //5min bevor man ausgelogt wird
      }
    },
    resetTime() {
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    async logoutUser() {
      try {
        await this.$store.dispatch("auth/logout").then(() => {
          window.localStorage.clear();
          this.$router.push("/login");
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.decline = "Schließen";
      }
    },
    errorMessage() {
      this.error = null;
    },
  },
  async mounted() {
    await this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTime);
    }, this);
    this.setTimers();
  },
};
</script>

