import router from '../../router.js';
import api_link from '../../config/variables.js';
import store from '../index.js';



export default {
   namespaced: true,
   state() {
      return {
         token: null,
         token_iat: null,
         token_exp: null,
         refreshToken: null,
         refreshToken_iat: null,
         refreshToken_exp: null,
         currentUser: [{
            user_id: null,
            account_id: null,
         }],
         permissions: [{
            superadmin: false,
            admin: false,
         }],
         tokensIsNotValid: false,
      };
   },
   mutations: {
      setToken(state, payload) {
         state.token = payload.token;
         state.token_iat = payload.token_iat;
         state.token_exp = payload.token_exp;
         window.localStorage.setItem('token', JSON.stringify({
            token: state.token,
            token_iat: state.token_iat,
            token_exp: state.token_exp
         }))
      },
      setRefreshToken(state, payload) {
         state.refreshToken = payload.refreshToken;
         state.refreshToken_iat = payload.refreshToken_iat;
         state.refreshToken_exp = payload.refreshToken_exp;
         window.localStorage.setItem('refreshToken', JSON.stringify({
            refreshToken: state.refreshToken,
            refreshToken_iat: state.refreshToken_iat,
            refreshToken_exp: state.refreshToken_exp
         }))
      },
      setCurrentUser(state, payload) {
         state.currentUser[0].user_id = payload.user_id;
         state.currentUser[0].account_id = payload.account_id;
         window.localStorage.setItem('currentUser', JSON.stringify({
            user_id: state.currentUser[0].user_id,
            account_id: state.currentUser[0].account_id,
         }))
      },
      permissions(state, payload) {
         state.permissions[0].superadmin = payload.superadmin;
         state.permissions[0].admin = payload.admin;
         window.localStorage.setItem('permissions', JSON.stringify({
            superadmin: state.permissions[0].superadmin,
            admin: state.permissions[0].admin,
         }))
      },
      tokensIsNotValid(state, payload) {
         state.tokensIsNotValid = payload
         window.localStorage.setItem('tokensIsNotValid', JSON.stringify({
            tokensIsNotValid: state.tokensIsNotValid,
         }))
      },
      logout(state) {
         let newState = {};
         Object.keys(state).forEach(key => {
            newState[key] = null;
         });
         router.push('/login');
      },
   },
   actions: {
      logout(context) {
         context.commit('logout');
      },
      async login(context, payload) {
         //await führt den folgenden Code erst aus, wenn der Aufruf erfolgt und eine Antwort angekommen ist.
         const response = await fetch(api_link + '/access/users/login', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify([{
               username: payload.username,
               password: payload.password
            }]),
         });

         const responseLogin = await response.json();

         if (!response.ok) {
            const error = new Error(responseLogin.error || 'Failed to login!');
            throw error;
         }

         await context.commit('setToken', {
            token: responseLogin.token,
            token_iat: responseLogin.token_iat,
            token_exp: responseLogin.token_exp,
         });

         await context.commit('setRefreshToken', {
            refreshToken: responseLogin.refreshToken,
            refreshToken_iat: responseLogin.refreshToken_iat,
            refreshToken_exp: responseLogin.refreshToken_exp
         });

         await context.commit('permissions', {
            superadmin: responseLogin.superadmin,
            admin: responseLogin.admin
         })

         const token = await this.getters["auth/token"];

         const responseUser = await fetch(api_link + '/access/users/' + responseLogin.user_id + '?search={"includeFields": ["user_id", "account_id"]}', {
            method: 'GET',
            headers: {
               'Authorization': 'Bearer ' + token,
               'Content-Type': 'application/json',
            },
         });

         const answer = await responseUser.json();

         await context.commit('setCurrentUser', {
            user_id: answer.data[0].user_id,
            account_id: answer.data[0].account_id
         })

         if (responseLogin.token != null) {
            router.push('/welcome');
         }
      },

      async checkToken(context) {
         const token_iat = JSON.parse(window.localStorage.getItem("token")).token_iat;
         const token_exp = JSON.parse(window.localStorage.getItem("token")).token_exp;
         const refreshToken = JSON.parse(window.localStorage.getItem("refreshToken")).refreshToken;
         const refreshToken_exp = JSON.parse(window.localStorage.getItem("refreshToken")).refreshToken_exp;

         if ((Number(Date.now()) / 1000) > token_exp && (Number(Date.now()) / 1000) > refreshToken_exp) { //automatischer Logout wenn sowohl der AccessToken und RefreshToken abgelaufen sind
            store.dispatch('auth/logout').then(() => {
               window.localStorage.clear();
            });
            await store.dispatch("auth/tokensIsNotValid");
         } else {
            if ((Number(Date.now()) / 1000) > (token_iat + ((token_exp - token_iat) * 0.9))) {  //Wenn 90% (oder mehr) des Tokens (Access Tokens) abgelaufen sind, dann soll ein neuer Token (Access) von der Api bereit gestellt werden
               const response = await fetch(api_link + '/access/users/refreshToken', {
                  method: 'POST',
                  headers: {
                     'Authorization': 'Bearer ' + refreshToken,
                     'Content-Type': 'application/json',
                  },
                  body: JSON.stringify([{
                     refreshToken: refreshToken
                  }]),
               });

               const responseRefreshToken = await response.json();

               if (!response.ok) {
                  const error = new Error(responseRefreshToken.error.message || 'Failed renew token!');
                  throw error;
               }
               context.commit('setToken', {
                  token: responseRefreshToken.token,
                  token_iat: responseRefreshToken.token_iat,
                  token_exp: responseRefreshToken.token_exp,
               });
            }
         }
      },
      tokensIsNotValid(context) {
         context.commit('tokensIsNotValid', true);
      }
   },
   getters: {
      token(state) {
         return state.token;
      },
      token_iat(state) {
         return state.token_iat;
      },
      token_exp(state) {
         return state.token_exp;
      },
      refreshToken(state) {
         return state.refreshToken;
      },
      refreshToken_iat(state) {
         return state.refreshToken_iat;
      },
      refreshToken_exp(state) {
         return state.refreshToken_exp;
      },
      tokensIsNotValid(state) {
         return state.tokensIsNotValid;
      },
      currentUser(state) {
         return state.currentUser[0];
      },
      permissions(state) {
         return state.permissions[0];
      },
      isAuthenticated(state) {
         return !!state.token;
      }
   }
}