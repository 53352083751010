import { createRouter, createWebHashHistory } from 'vue-router';



import Login from './pages/login/Login.vue';
import Welcome from './pages/Welcome.vue';
// import Permissions from './pages/permission/Permissions.vue';
// import showAllUser from './pages/superAdmin/showAllUser.vue';
// import Users from './pages/user/Users.vue';
// import EditUser from './pages/user/EditUser.vue';
// import Installations from './pages/installation/Installations.vue';
// import EditInstallation from './pages/installation/EditInstallation.vue';
// import Watchdogs from './pages/watchdog/Watchdogs.vue';
// import EditWatchdog from './pages/watchdog/EditWatchdog.vue';

// import store from './store/index.js';

// const Permissions = defineAsyncComponent(() => import('./pages/permission/Permissions.vue'))

const router = createRouter({
  history: createWebHashHistory('/'),
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    { path: '/welcome', component: Welcome, meta: { requiresAuth: true } },
    { path: '/permissions', component: () => import('./pages/permission/Permissions.vue'), meta: { requiresAuth: true, requireAdmin: true } },
    // { path: '/users', component: () => import('./pages/user/Users.vue'), meta: { requiresAuth: true } },
    // { path: '/edit-user', component: () => import('./pages/user/EditUser.vue'), meta: { requiresAuth: true } },
    // { path: '/installations', component: () => import('./pages/installation/Installations.vue'), meta: { requiresAuth: true } },
    // { path: '/edit-installation', component: () => import('./pages/installation/EditInstallation.vue'), meta: { requiresAuth: true } },
    // { path: '/watchdogs', component: () => import('./pages/watchdog/Watchdogs.vue'), meta: { requiresAuth: true } },
    // { path: '/edit-watchdog', component: () => import('./pages/watchdog/EditWatchdog.vue'), meta: { requiresAuth: true } },
  ],
});

router.beforeEach(function (to, from, next) {
  const auth = !!window.localStorage.getItem("token");
  if (to.meta.requiresAuth && !auth) { // Überprüft ob ein Token vorhanden ist, wenn nein geh zurück zur Login-Seite (Gültigkeit des Tokens wird in jedem API Aufruf einzelnd überprüft)
    next('/login');
  }
  else {
    if (to.meta.requireAdmin == true) { // Überprüft ob die aufzurufende Seite nur für Admins ist
      if (JSON.parse(window.localStorage.getItem("permissions")).admin == true) {  //Prüft die Berechtigung des angemeldeten Benutzer
        next();
      } else {
        return
      }
    } else {
      next();
    }
  }
});


export default router;