import api_link from '../../config/variables.js';
import refreshToken from '../../components/controllers/refreshToken.vue';


export default {
   namespaced: true,
   state() {
      return {
         watchdogs: [],
         singleWatchdog: null,
         isEdit: 0,
         selectedUser: null
      };
   },
   mutations: {
      watchdogs(state, payload) {
         state.watchdogs = payload.data;
      },
      singleWatchdog(state, payload) {
         state.singleWatchdog = payload.data[0];
      },
      isEdit(state, payload) {
         state.isEdit = payload
      },
      selectedUser(state, payload) {
         state.selectedUser = payload
      }
   },
   actions: {
      async getWatchdogs(context) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/watchdogs/overview', {
               method: 'GET',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               }
            });
            const responseWatchdogs = await response.json();
            if (!response.ok) {
               const error = new Error(responseWatchdogs.error || 'Failed to load data from API!');
               throw error;
            }

            context.commit('watchdogs', responseWatchdogs);
         }
      },
      async getSingleWatchdog(context, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            if (payload.isEdit == 1) { //Prüft ob man einen bestehenden Eintrag updaten will oder der nicht. (1 = updaten || 0 = nur den Status auf "neu anlegen" setzen)
               const token = this.getters["auth/token"];
               const response = await fetch(api_link + '/watchdogs/' + payload.id, { //Wird neu ausgeführt damit man die aktuelsten Daten hat und nicht "veraltete" aus dem Datengitter
                  method: 'GET',
                  headers: {
                     'Authorization': 'Bearer ' + token,
                     'Content-Type': 'application/json',
                  }
               });
               const responseWatchdog = await response.json();

               if (!response.ok) {
                  const error = new Error(responseWatchdog.error || 'Failed to load data from API!');
                  throw error;
               }
               context.commit('singleWatchdog', responseWatchdog);
            }
            context.commit('isEdit', payload.isEdit);
            context.commit('selectedUser', payload.selectedUser);
         }
      },
      async createWatchdog(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/watchdogs', {
               method: 'POST',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify([{
                  watchdog_id: payload.watchdog_id,
                  user_id: payload.user_id,
                  name: payload.name,
                  disabled: payload.disabled
               }]),
            });
            const responseWatchdog = await response.json();

            if (!response.ok) {
               const error = new Error(responseWatchdog.error || 'Failed to create Watchdog!');
               throw error;
            }
         }
      },
      async updateWatchdog(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/watchdogs/' + payload.id, {
               method: 'PATCH',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify([{
                  user_id: payload.user_id,
                  name: payload.name,
                  disabled: payload.disabled
               }]),
            });
            const responseWatchdog = await response.json();

            if (!response.ok) {
               const error = new Error(responseWatchdog.error || 'Failed to update Watchdog!');
               throw error;
            }
         }
      },
      async deleteWatchdog(_, payload) {
         await refreshToken.methods.checkToken();
         if (this.getters["auth/tokensIsNotValid"] == false) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/watchdogs/' + payload.id, {
               method: 'DELETE',
               headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
               }
            });
            const responseWatchdogs = await response.json();

            if (!response.ok) {
               const error = new Error(responseWatchdogs.error.message || 'Failed to delete Watchdog!');
               throw error;
            }
         }
      }
   },
   getters: {
      watchdogs(state) {
         return state.watchdogs;
      },
      singleWatchdog(state) {
         return state.singleWatchdog;
      },
      isEdit(state) {
         return state.isEdit;
      },
      selectedUser(state) {
         return state.selectedUser
      }
   }
}